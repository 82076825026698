import {
  ReportTableCell as ReportCell,
  ProcessedRowWithMetadata as ReportRow,
} from '@clarity-website/react-table-data-handler/common/types';
import { Cell } from '@tanstack/react-table';
import { ColumnDef, GroupColumnDef } from '@tanstack/table-core';

// dirty hack, do not use willingly
export function rtoHeaderRearrangement<TData, TValue>(
  columnDef: ColumnDef<TData, TValue>[],
) {
  const [, , nonFrozenHeader] = columnDef;

  const newNonFrozenColumns =
    (nonFrozenHeader as GroupColumnDef<TData, TValue>)?.columns?.map(
      (parentColumn, index: number) => {
        const childColumns = (parentColumn as GroupColumnDef<TData, TValue>)
          .columns;

        if (childColumns?.length) {
          const firstColumn = childColumns[0];
          return {
            ...parentColumn,
            header: index === 0 ? firstColumn?.header : '',
            columns: [
              {
                ...firstColumn,
                header: parentColumn.header,
              },
              ...childColumns.slice(1),
            ],
          };
        }
        return parentColumn;
      },
    ) || [];

  return [
    ...columnDef.slice(0, 2),
    {
      ...nonFrozenHeader,
      columns: newNonFrozenColumns,
    },
  ];
}

const DAYS_BADGED_ATTRIBUTES = ['A1729615466', 'A1729615464'];
const PTO_ATTRIBUTE = 'A1697685053';
const HOLIDAY_ATTRIBUTE = 'A1737139886';
const RTO_REQUIRED_ATTRIBUTE = 'A1694884480';

export function isDaysOfWeekBadgedAttribute(attribute?: string) {
  return DAYS_BADGED_ATTRIBUTES.includes(attribute?.split('#')?.[0] || '');
}

// fetches from adjacent RTO columns for the same frame of week
// returns the holidays, pto, and RTO required fields
export function getRTORelatedProperties(cell: Cell<ReportRow, ReportCell>) {
  const holidays = getAdjacentCellAttributeValue(cell, HOLIDAY_ATTRIBUTE);
  const pto = getAdjacentCellAttributeValue(cell, PTO_ATTRIBUTE);
  const rtoRequiredValue = getAdjacentCellAttributeValue(
    cell,
    RTO_REQUIRED_ATTRIBUTE,
  );
  return {
    holidays,
    pto,
    rtoRequired: rtoRequiredValue.toLowerCase() === 'y',
  };
}

export function getAdjacentCellAttributeValue(
  cell: Cell<ReportRow, ReportCell>,
  attributeId: string,
) {
  const currentWeek = cell.id?.split('#')[1];
  const adjacentCells = cell.row
    ?.getAllCells()
    .filter((it) => it.id.includes(currentWeek));
  const attributeCell = adjacentCells
    ?.find((it) => it.id.includes(attributeId))
    ?.getValue() as ReportCell;
  return attributeCell?.value || '';
}
